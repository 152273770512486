/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable @typescript-eslint/no-this-alias */
import { memo, useEffect, useState, useRef } from 'react';
import { useTheme } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import root from 'react-shadow';

import angular2react from './angular2react';
import { aiPcapInput } from './directives';
import aiFileInput from './directives/aiFileInput.directive';
import FileFactory from './factories/file.factory';

import vendorScript from '!!raw-loader!../../dist/js/vendor.min.js';
import bootstrap from '!!to-string-loader!css-loader!../../dist/css/bootstrap.min.css';
import fontAwesome from '!!to-string-loader!css-loader!../../dist/css/font-awesome.min.css';

const AiSchemaForm = memo(
  angular2react('aiSchemaForm', ['schema', 'form', 'model', 'onModelChange', 'onStateChange', 'scopeRef']),
  (prevProps, nextProps) => prevProps.toggle === nextProps.toggle
);

export default ({ fullHeight = false, ...props }) => {
  const { palette, spacing } = useTheme();
  const [shouldShow, setShouldShow] = useState(false); // We need to delay AiSchemaForm constructor until we are sure angularJS is loaded
  const angularStyles = `
  [ng\\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak],
  .ng-cloak, .x-ng-cloak,
  .ng-hide:not(.ng-hide-animate) {
    display: none !important;
  }

  ng\\:form {
    display: block;
  }


  .Mui-focused a {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
  }

  #file-input {
    display: none;
  }

  #pcap-input {
    display: none;
  }
`;

  const ref = useRef(null);

  useEffect(() => {
    window.ASFShadowRoot = ref.current.shadowRoot; // This needs to be set so the custom directives can find the elements
    if (window.angular === undefined) {
      const ASFScript = document.createElement('script');
      ASFScript.innerHTML = vendorScript;
      document.body.appendChild(ASFScript);
    }

    // Let the browser parse the script tag before trying to bootstrap the angularjs app
    setTimeout(() => {
      angular
        .module('ASFApp', ['schemaForm', 'ngResource', 'ngFileUpload', 'ui.sortable'])
        .config([
          '$interpolateProvider',
          'schemaFormDecoratorsProvider',
          function config($interpolateProvider, schemaFormDecoratorsProvider) {
            schemaFormDecoratorsProvider.addMapping(
              'bootstrapDecorator',
              'json-editor',
              'angular-schema-form-json-editor.html'
            );

            schemaFormDecoratorsProvider.addMapping('bootstrapDecorator', 'ace-editor', 'angular-ace-editor.html');

            schemaFormDecoratorsProvider.addMapping(
              'bootstrapDecorator',
              'ui-select',
              'angular-schema-form-ui-select.html'
            );

            schemaFormDecoratorsProvider.addMapping(
              'bootstrapDecorator',
              'ui-select-multiple',
              'angular-schema-form-ui-select-multiple.html'
            );

            $interpolateProvider.startSymbol('[[');
            $interpolateProvider.endSymbol(']]');
          }
        ])
        .factory('FileFactory', FileFactory)
        .directive('aiSchemaForm', function aiSchemaForm() {
          return {
            restrict: 'E',
            scope: {
              schema: '=',
              form: '=',
              model: '=',
              onModelChange: '=',
              onStateChange: '=',
              scopeRef: '='
            },
            replace: true,
            controllerAs: 'vm',
            bindToController: true,
            controller: 'aiSchemaFormController',
            template:
              '<div class="ai-schema-form"><form name="vm.schemaForm" sf-schema="vm.schema" sf-form="vm.form" sf-model="vm.model" autocomplete="off"></form></div>'
          };
        })
        .controller('aiSchemaFormController', [
          '$scope',
          '$timeout',
          function aiSchemaFormController($scope, $timeout) {
            var vm = this;
            vm.schemaForm = {};

            if (vm.scopeRef) {
              vm.scopeRef.current = $scope;
            }

            $scope.$watch(
              'vm.model',
              function (newVal, oldVal) {
                if (
                  typeof vm.onModelChange === 'function' &&
                  !isEmpty(newVal) &&
                  !isEmpty(oldVal) &&
                  !isEqual(newVal, oldVal)
                ) {
                  vm.onModelChange(newVal);
                }
              },
              true
            );

            $scope.$watchGroup(['vm.schemaForm.$invalid', 'vm.schemaForm.$pristine'], function () {
              if (typeof vm.onStateChange === 'function') {
                $timeout(function () {
                  vm.onStateChange(vm.schemaForm);
                });
              }
            });
          }
        ]);

      const angularApp = angular.element(document.querySelector('body')).injector();

      if (angularApp === undefined) {
        angular.element(document).ready(function () {
          angular.bootstrap(document, ['ASFApp']);
        });
      }

      // Registers custom directives
      angular.module('ASFApp').directive('aiPcapInput', aiPcapInput);
      angular.module('ASFApp').directive('aiFileInput', aiFileInput);

      setTimeout(() => {
        setShouldShow(true);
      }, 0);
    }, 0);

    return () => {
      window.ASFShadowRoot = null;
    };
  }, [ref]);

  return (
    <>
      <root.div
        ref={ref}
        mode="open"
        data-testid="shadow-root-react-asf"
        style={{ height: fullHeight ? '100%' : 'initial' }}
      >
        {shouldShow && (
          <div className="root">
            <AiSchemaForm {...props} />
          </div>
        )}
        <style type="text/css">{bootstrap}</style>
        <style type="text/css">{fontAwesome}</style>
        <style type="text/css">{angularStyles}</style>
        <style type="text/css">{`
          .root {
            border: 1px solid;
            height: fit-content;
            color: ${palette.text.primary};
            background-color: ${palette.background.default};
            border-color: ${palette.divider};
            border-radius: ${spacing(0.5)};
            padding: ${spacing(1)};
          }

          .form-control {
            color: ${palette.text.primary};
            background-color: ${palette.background.default};
            border-color: ${palette.divider};
          }

          .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
            background-color: ${palette.action.disabledBackground};
          }

          .has-success .form-control {
            border-color: ${palette.success.dark};
          }

          .has-error .form-control {
            border-color: ${palette.error.dark};
          }

          .has-warning .form-control {
            border-color: ${palette.warning.dark};
          }

          .list-group-item {
            background-color: ${palette.background.default};
            border-color: ${palette.divider};
          }

          .close {
            color: ${palette.text.primary};
            opacity: 1;
          }

          .btn {
            color: ${palette.primary.contrastText};
            background-color: ${palette.primary.main};
          }

          .btn.focus, .btn:focus, .btn:hover, .btn:active:hover {
            color: ${palette.text.secondary};
            background-color: ${palette.primary.main};
          }
            
          .btn.active {
            color: ${palette.primary.contrastText};
            background-color: ${palette.primary.dark};
          }

          .btn.active.focus, .btn.active:focus, .btn.active:hover, .btn.active:active:hover {
            color: ${palette.text.secondary};
            background-color: ${palette.primary.dark};
          }

          legend {
            color: ${palette.text.primary};
          }

          .has-success .checkbox, .has-success .checkbox-inline, .has-success .control-label, .has-success .help-block, .has-success .radio, .has-success .radio-inline, .has-success.checkbox label, .has-success.checkbox-inline label, .has-success.radio label, .has-success.radio-inline label {
            color: ${palette.success.dark};
          }

          .has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label {
            color: ${palette.error.dark};
          }

          .has-warning .checkbox, .has-warning .checkbox-inline, .has-warning .control-label, .has-warning .help-block, .has-warning .radio, .has-warning .radio-inline, .has-warning.checkbox label, .has-warning.checkbox-inline label, .has-warning.radio label, .has-warning.radio-inline label {
            color: ${palette.warning.dark};
          }
        `}</style>
        <style type="text/css">
          {`
            .required-asterisk {
                color: ${palette.error.main}
              }
            .required-asterisk::before {
              content: "(Required) ";
              color: ${palette.text.primary}
            }
          `}
        </style>
      </root.div>
      {/* The browser doesn't load the fonts requested by the shadow-dom  */}
      <style type="text/css">{fontAwesome}</style>
    </>
  );
};
