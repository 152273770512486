import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps, Skeleton, Tooltip } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { useVendor } from '@attackiq/queries';

interface VendorLogoProps extends MuiAvatarProps {
  vendor: string;
}

export const VendorLogo = ({ vendor, sx, ...props }: VendorLogoProps) => {
  const { isLoading, data } = useVendor(vendor);

  if (isLoading) {
    return (
      <Skeleton variant="circular" animation="wave" sx={{ flexShrink: 0 }}>
        <MuiAvatar sx={sx} />
      </Skeleton>
    );
  }

  const srcSet = !isEmpty(data?.logo)
    ? `${data?.logo.thumbnail_50} 50w,
       ${data?.logo.thumbnail_100} 100w`
    : undefined;

  return (
    <Tooltip title={data?.name ?? ''} disableInteractive arrow>
      <MuiAvatar
        src={data?.logo?.thumbnail_50}
        srcSet={srcSet}
        alt={`${data?.name} logo`}
        data-testid={`${data?.id}-avatar`}
        sx={{ ...sx, '& img': { width: 24, height: 24 } }}
        {...props}
      />
    </Tooltip>
  );
};
