import { useState } from 'react';
import { Autocomplete, Box, Chip, FormControlLabel, Switch, TextField } from '@mui/material';
import { useCreate, useNavigation } from '@refinedev/core';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { format } from 'date-fns';
import { FieldValues, FormProvider, SubmitHandler, useController, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { TextFieldInput } from '@attackiq/components';
import { ATTACKIQ_ID } from '@attackiq/constants';
import { fileToBase64 } from '@attackiq/utils';

import DragAndDrop from '../../components/DragAndDrop';
import { New } from '../../types';

export const FeaturedInput = () => {
  const { control } = useFormContext();
  const { field } = useController({
    name: 'featured',
    control
  });

  const options = [
    { model: false, displayName: 'No' },
    { model: true, displayName: 'Yes' }
  ];

  return (
    <Autocomplete
      renderInput={params => <TextField {...params} label="Featured" />}
      options={options}
      onChange={(_e, value) => {
        field.onChange(value?.model);
      }}
      value={options.find(option => option.model === field.value)}
      getOptionLabel={option => option.displayName}
    />
  );
};

export const ActiveInput = () => {
  const { control } = useFormContext();
  const { field } = useController({
    name: 'active',
    control
  });

  const options = [
    { model: false, displayName: 'No' },
    { model: true, displayName: 'Yes' }
  ];

  return (
    <Autocomplete
      renderInput={params => <TextField {...params} label="Active" />}
      options={options}
      onChange={(_e, value) => {
        field.onChange(value?.model);
      }}
      value={options.find(option => option.model === field.value)}
      getOptionLabel={option => option.displayName}
    />
  );
};

const NewCreate = () => {
  const { mutateAsync: createMutateAsync, isLoading } = useCreate();

  const { edit } = useNavigation();

  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);
  const [urlEnabled, setUrlEnabled] = useState(false);

  const methods = useForm<New>({
    refineCoreProps: { action: 'create' },
    defaultValues: {
      content_id: '',
      content_type_name: '',
      description: '',
      url: '',
      company: ATTACKIQ_ID,
      featured: false,
      cover_image: '',
      date: '',
      id: '',
      modules: [],
      scenario_tag_ids: [],
      title: '',
      active: false,
      type: 'whats_new',
      order: 0
    }
  });

  const { handleSubmit, setValue } = methods;

  const onSubmit: SubmitHandler<New> = async newContent => {
    const payload = {
      ...newContent,
      id: uuidv4(),
      date: format(new Date(), 'yyyy-MM-dd')
    };

    if (!urlEnabled) {
      payload.cover_image = selectedFile ? await fileToBase64(selectedFile) : '';
    }

    const { data: newlyCreatedNew } = await createMutateAsync({
      resource: 'whats_new',
      values: payload
    });

    if (!newlyCreatedNew.id) return;

    edit('whats_new', newlyCreatedNew.id);
  };

  return (
    <FormProvider {...methods}>
      <form
        name="create-module-form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        autoComplete="off"
      >
        <Create isLoading={isLoading} saveButtonProps={{ type: 'submit' }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <ActiveInput />
            <TextFieldInput color="primary" name="title" label="Title" rules={{ required: 'Title is required' }} />
            <TextFieldInput
              color="primary"
              name="content_id"
              label="Content ID"
              rules={{ required: 'Content ID is required' }}
            />
            <TextFieldInput
              color="primary"
              name="content_type_name"
              label="Content Type Name"
              rules={{ required: 'Content Type Name is required' }}
            />
            <TextFieldInput
              color="primary"
              name="order"
              label="Order"
              type="number"
              rules={{ required: 'Order is required' }}
            />
            <TextFieldInput
              color="primary"
              multiline
              rows={5}
              name="description"
              label="Description"
              rules={{ required: 'Description is required' }}
            />
            <TextFieldInput color="primary" name="url" label="URL" rules={{ required: 'URL is required' }} />
            <ModulesInput />
            <ScenarioTagIDSInput />
            <FeaturedInput />
            <FormControlLabel
              label="Use URL to upload cover image"
              control={
                <Switch
                  value={urlEnabled}
                  onChange={e => {
                    setValue('cover_image', '');
                    setUrlEnabled(e.target.checked);
                  }}
                />
              }
            />
            {urlEnabled ? (
              <TextFieldInput
                color="primary"
                name="cover_image"
                label="Cover Image URL"
                rules={{
                  required: 'Cover Image is required',
                  pattern: { value: /https?:\/\/.*/, message: 'Invalid URL' }
                }}
              />
            ) : (
              <DragAndDrop
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                acceptType=".png, .jpg, .jpeg, .svg, .webp"
              />
            )}
          </Box>
        </Create>
      </form>
    </FormProvider>
  );
};

export const ScenarioTagIDSInput = () => {
  const { field } = useController({ name: 'scenario_tag_ids' });

  return (
    <Autocomplete
      options={[]}
      freeSolo
      sx={{ flex: 1 }}
      disableCloseOnSelect
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={option} label={option} />);
      }}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          color="primary"
          label={'Scenario Tag IDS'}
          helperText="Press enter after each ID"
        />
      )}
      value={field.value ?? []}
      onChange={(_e, v) => {
        field.onChange(v);
      }}
      multiple
    />
  );
};

export const ModulesInput = () => {
  const { field } = useController({ name: 'modules' });

  return (
    <Autocomplete
      options={[]}
      freeSolo
      sx={{ flex: 1 }}
      disableCloseOnSelect
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={option} label={option} />);
      }}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          color="primary"
          label={'Modules'}
          helperText="Press enter after each Module"
        />
      )}
      value={field.value ?? []}
      onChange={(_e, v) => {
        field.onChange(v);
      }}
      multiple
    />
  );
};

export default NewCreate;
